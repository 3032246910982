.fullscreen-bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -100;
}
.video-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*filter: blur(2px);
  -webkit-filter: blur(2px);*/
}
@media (min-aspect-ratio: 16/9) {
  .video-bg {
    height: 300%;
    top: -100%;
  }
}
@media (max-aspect-ratio: 16/9) {
  .video-bg{
    width: 300%;
    left: -100%;
  }
}
@media (max-width: 768px) {
  /* 768 */
  .fullscreen-bg {
    background: url("../images/main_small.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
  }
  .video-bg {
    display: none;
  }
}