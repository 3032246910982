header {
  background-color: #36A4AA;
  display: flex;
  position: fixed;
  z-index: 500;
  width: 100%;
  top: 0;
  left: 0;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}

.title {
  padding-left: 20px;
  text-transform: uppercase;
}

.title-link {
  text-shadow: 2px 2px 2px rgba(48, 48, 48, 1);
  font-family: 'Press Start 2P', cursive;
  font-weight: 900;
  font-size: 24px;
  text-decoration: none;
  color: #fff;
}

.title-link > span {
  color: #E73D1A;
}

nav {
  padding-right: 20px;
}

nav a {
  padding: 0px 10px;
  text-decoration: none;
  color: #fff;
  padding-bottom: 17px;
  padding-top: 18px;
}

nav a:hover {
  background-color: hsl(183, 52%, 34%);
  border-bottom: 2px solid hsl(183, 52%, 34%);
}


nav a.active {
  border-bottom: 2px solid #fff;
}

nav a.active:hover {
  background-color: #36A4AA;
}

@media (max-width: 1020px) { 
  header {
    flex-direction: column;
    justify-content: space-evenly;
    height: 90px;
  }
  nav {
    padding: 0;
  }
  nav a {
    padding-bottom: 10px;
  }
  nav a:hover {
    padding-top: 12px;
  }
}

@media (max-width: 660px) {
  header {
    height: 50px;
    justify-content: center;
  }
  nav {
    font-size: 11px;
  }
  .title {
    padding: 0;
  }
  .title-link {
    font-size: 13px;
  }
  nav a {
    padding-bottom: 5px;
  }
  nav a:hover {
    padding-top: 2px;
    border-bottom: 1px solid hsl(183, 52%, 34%);
  }
  nav a.active {
    border-bottom: 1px solid #fff;
  }
}