
.App {
  width: 90%;
  padding-top: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App h1,
.App h2,
.App h3,
.App h4,
.App p {
  max-width: 700px;
  color: #fff;
  text-shadow: 2px 2px 2px rgba(48, 48, 48, 1);
  margin: 0;
}

.intro {
  font-size: 13px;
}

main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header-margin {
}

.box-wrapper {
  word-break: break-word;
  border-radius: 15px;
  background: rgba(54, 164, 170,0.8);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.box-wrapper h2,
.box-wrapper h3,
.box-wrapper h4 {
  color: #D7CB83;
}

.box-wrapper.normal {
  max-width: 700px;
  margin-top: 20px;
  padding: 20px;
  font-size: 12px;
}

.box-wrapper.normal h2 {
  margin: 10px 0;
}

.box-wrapper.normal p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  text-shadow: none;
}

.box-wrapper.normal strong {
  font-size: 16px;
  color: #D7CB83;
}

.box-wrapper.normal a {
  text-shadow: 0 0 1px black;
  color: hsl(47, 58%, 57%);
  text-decoration: none;
}

.box-wrapper.normal a:hover {
  color: hsl(47, 58%, 67%);
}

.box-wrapper.normal ol {
  color: rgba(255, 255, 255, 0.8);
  text-align: left;
}

.box-wrapper.normal ol strong {
  font-size: 14px;
  color: #D7CB83;
}

.box-wrapper.normal > ol > li {
  padding: 6px 0;
}

.box-wrapper.upload > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-wrapper {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  padding: 0 20px 10px 20px;
}

.form-wrapper p {
  color: rgba(0, 0, 0, 0.54);
}

.chart-container {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}

.chart-container.character {
  padding-top: 20px;
}

#range-slider {
  text-shadow: none;
}

#activity-chart {
  background: #36a4aa
}

h2.highlight,
strong.stroke {
  margin-right: 6px;
  color: #fff !important;
  text-shadow: -1px -1px 0 #7A3C3C,1px -1px 0 #7A3C3C,-1px 1px 0 #7A3C3C,1px 1px 0 #7A3C3C
}

h2.highlight {
  font-size: 24px;
}

span.error {
  color: #fff !important;
  text-shadow: -1px -1px 0 #7A3C3C,1px -1px 0 #7A3C3C,-1px 1px 0 #7A3C3C,1px 1px 0 #7A3C3C
}

footer {
  color: rgba(255,255,255,0.4);
  margin-top: 30px;
  font-size: 10px;
  text-align: center;
  max-width: 700px;
}

footer > ul {
  list-style-type: none;
  color: #fff;
}

footer > ul > li > a,
footer > ul > li > a:visited,
footer > ul > li > a:hover,
footer > ul > li > a:active {
  color: #fff;
  text-decoration: none;
}

#overview-chart {
  background: #36a4aa;
  margin-top: 15px;
}

.activity-wrapper {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.filter-form {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.filter-form p {
  text-shadow: none;
}

.filter-form ul {
  text-align: left;
  margin: 10px 0 0 0;
  padding: 0;
}

.filter-form button.list {
     background:none;
     color:inherit;
     border:none; 
     padding:0!important;
     font: inherit;
     cursor: pointer;
}

.filter-form button.list:hover {
  color: hsl(0, 0%, 30%);
}

.filter-form ul li {
  list-style: circle;
  font-size: 10px;
}

.discord-cta {
  border: 4px solid #fff;
  margin: 10px 0;
  padding: 20px 30px;
  border-radius: 15px;
  background-color: #CE3C55;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.discord-cta > a {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000
}

.discord-cta > a > p {
  padding-left: 10px;
  color: #000;
  text-shadow: none;
  text-transform: uppercase;
}

@media (max-width: 1020px) { 
  .App {
    padding-top: 100px;
  }
}

@media (max-width: 700px) {
  .App {
    width: 96%;
    padding-top: 50px;
  }
  .App h1 {
    margin-top: 10px;
    font-size: 20px;
  }
  .App h2 {
    font-size: 18px;
  }
  .App h3 {
    font-size: 16px;
  }
  .App p {
    font-size: 12px;
  }
  footer p {
    font-size: 8px;
  }
}

#alert-dialog-description a {
  color: rgb(224, 52, 52);
  text-decoration: none;
}

#alert-dialog-description a:hover {
  color: rgb(128, 28, 28);
}