@import url(https://fonts.googleapis.com/css?family=Open+Sans:800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Press+Start+2P&display=swap);
.fullscreen-bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -100;
}
.video-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*filter: blur(2px);
  -webkit-filter: blur(2px);*/
}
@media (min-aspect-ratio: 16/9) {
  .video-bg {
    height: 300%;
    top: -100%;
  }
}
@media (max-aspect-ratio: 16/9) {
  .video-bg{
    width: 300%;
    left: -100%;
  }
}
@media (max-width: 768px) {
  /* 768 */
  .fullscreen-bg {
    background: url(/static/media/main_small.b2e93085.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
  }
  .video-bg {
    display: none;
  }
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.App {
  width: 90%;
  padding-top: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App h1,
.App h2,
.App h3,
.App h4,
.App p {
  max-width: 700px;
  color: #fff;
  text-shadow: 2px 2px 2px rgba(48, 48, 48, 1);
  margin: 0;
}

.intro {
  font-size: 13px;
}

main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header-margin {
}

.box-wrapper {
  word-break: break-word;
  border-radius: 15px;
  background: rgba(54, 164, 170,0.8);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.box-wrapper h2,
.box-wrapper h3,
.box-wrapper h4 {
  color: #D7CB83;
}

.box-wrapper.normal {
  max-width: 700px;
  margin-top: 20px;
  padding: 20px;
  font-size: 12px;
}

.box-wrapper.normal h2 {
  margin: 10px 0;
}

.box-wrapper.normal p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  text-shadow: none;
}

.box-wrapper.normal strong {
  font-size: 16px;
  color: #D7CB83;
}

.box-wrapper.normal a {
  text-shadow: 0 0 1px black;
  color: hsl(47, 58%, 57%);
  text-decoration: none;
}

.box-wrapper.normal a:hover {
  color: hsl(47, 58%, 67%);
}

.box-wrapper.normal ol {
  color: rgba(255, 255, 255, 0.8);
  text-align: left;
}

.box-wrapper.normal ol strong {
  font-size: 14px;
  color: #D7CB83;
}

.box-wrapper.normal > ol > li {
  padding: 6px 0;
}

.box-wrapper.upload > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-wrapper {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  padding: 0 20px 10px 20px;
}

.form-wrapper p {
  color: rgba(0, 0, 0, 0.54);
}

.chart-container {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}

.chart-container.character {
  padding-top: 20px;
}

#range-slider {
  text-shadow: none;
}

#activity-chart {
  background: #36a4aa
}

h2.highlight,
strong.stroke {
  margin-right: 6px;
  color: #fff !important;
  text-shadow: -1px -1px 0 #7A3C3C,1px -1px 0 #7A3C3C,-1px 1px 0 #7A3C3C,1px 1px 0 #7A3C3C
}

h2.highlight {
  font-size: 24px;
}

span.error {
  color: #fff !important;
  text-shadow: -1px -1px 0 #7A3C3C,1px -1px 0 #7A3C3C,-1px 1px 0 #7A3C3C,1px 1px 0 #7A3C3C
}

footer {
  color: rgba(255,255,255,0.4);
  margin-top: 30px;
  font-size: 10px;
  text-align: center;
  max-width: 700px;
}

footer > ul {
  list-style-type: none;
  color: #fff;
}

footer > ul > li > a,
footer > ul > li > a:visited,
footer > ul > li > a:hover,
footer > ul > li > a:active {
  color: #fff;
  text-decoration: none;
}

#overview-chart {
  background: #36a4aa;
  margin-top: 15px;
}

.activity-wrapper {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.filter-form {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.filter-form p {
  text-shadow: none;
}

.filter-form ul {
  text-align: left;
  margin: 10px 0 0 0;
  padding: 0;
}

.filter-form button.list {
     background:none;
     color:inherit;
     border:none; 
     padding:0!important;
     font: inherit;
     cursor: pointer;
}

.filter-form button.list:hover {
  color: hsl(0, 0%, 30%);
}

.filter-form ul li {
  list-style: circle;
  font-size: 10px;
}

.discord-cta {
  border: 4px solid #fff;
  margin: 10px 0;
  padding: 20px 30px;
  border-radius: 15px;
  background-color: #CE3C55;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.discord-cta > a {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000
}

.discord-cta > a > p {
  padding-left: 10px;
  color: #000;
  text-shadow: none;
  text-transform: uppercase;
}

@media (max-width: 1020px) { 
  .App {
    padding-top: 100px;
  }
}

@media (max-width: 700px) {
  .App {
    width: 96%;
    padding-top: 50px;
  }
  .App h1 {
    margin-top: 10px;
    font-size: 20px;
  }
  .App h2 {
    font-size: 18px;
  }
  .App h3 {
    font-size: 16px;
  }
  .App p {
    font-size: 12px;
  }
  footer p {
    font-size: 8px;
  }
}

#alert-dialog-description a {
  color: rgb(224, 52, 52);
  text-decoration: none;
}

#alert-dialog-description a:hover {
  color: rgb(128, 28, 28);
}
.arc {
  text-shadow: 0 0 2px #000;
}
.faction-pie.alliance {
  fill: #223355;
}

.faction-pie.horde {
  fill: #883333;
}

.faction-pie.text.alliance {
  fill: #923148;
}

.faction-pie.text.horde {
  fill:#456F72;
}

.fix-slider-rail {
  left:0;
}
.character-chart {
  padding-top: 10px;
  text-align: center;
  max-width: 700px;
}

@media (max-width: 700px) {
  .character-chart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .character-chart > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}


.character-chart h2,
.character-chart h3,
.character-chart h4 {
  margin: 0;
}

.character-chart text {
  font-size: 12px;
}
/* Taken from https://github.com/caged/d3-tip/blob/master/examples/example-styles.css */
.d3-tip {
  line-height: 1;
  font-weight: bold;
  padding: 12px;
  background: rgba(158, 123, 9, 0.8);
  color: #fff;
  border-radius: 2px;
  pointer-events: none;
}

/* Creates a small triangle extender for the tooltip */
.d3-tip:after {
  box-sizing: border-box;
  display: inline;
  font-size: 10px;
  width: 100%;
  line-height: 1;
  color: rgba(158, 123, 9, 0.8);
  position: absolute;
  pointer-events: none;
}

/* Northward tooltips */
.d3-tip.n:after {
  content: "\25BC";
  margin: -1px 0 0 0;
  top: 100%;
  left: 0;
  text-align: center;
}

/* Eastward tooltips */
.d3-tip.e:after {
  content: "\25C0";
  margin: -4px 0 0 0;
  top: 50%;
  left: -8px;
}

/* Southward tooltips */
.d3-tip.s:after {
  content: "\25B2";
  margin: 0 0 1px 0;
  top: -8px;
  left: 0;
  text-align: center;
}

/* Westward tooltips */
.d3-tip.w:after {
  content: "\25B6";
  margin: -4px 0 0 -1px;
  top: 50%;
  left: 100%;
}

.d3-tip > div > p {
  padding: 0;
  margin: 0;
}

.bar {
  color: hsl(24, 49%, 49%);
}

.bar:hover {
  color: hsl(207, 44%, 39%);
}
.bar.classes_paladin{
  color: hsl(334, 84%, 75%);
}

.bar.classes_paladin:hover{
  color: hsl(334, 84%, 65%);
}

.bar.classes_druid{
  color: hsl(28, 100%, 52%);
}

.bar.classes_druid:hover{
  color: hsl(28, 100%, 42%);
}

.bar.classes_hunter{
  color: hsl(85, 53%, 64%);
}

.bar.classes_hunter:hover{
  color: hsl(85, 53%, 54%);
}

.bar.classes_mage{
  color: hsl(193, 81%, 59%);
}

.bar.classes_mage:hover{
  color: hsl(193, 81%, 49%);
}

.bar.classes_priest{
  color: hsl(0, 0%, 100%);
}

.bar.classes_priest:hover{
  color: hsl(0, 0%, 90%);
}

.bar.classes_rogue{
  color: hsl(56, 100%, 71%);
}

.bar.classes_rogue:hover{
  color: hsl(56, 100%, 61%);
}

.bar.classes_shaman{
  color: hsl(210, 100%, 44%);
}

.bar.classes_shaman:hover{
  color: hsl(210, 100%, 34%);
}

.bar.classes_warlock{
  color: hsl(240, 74%, 73%);
}

.bar.classes_warlock:hover{
  color: hsl(240, 74%, 63%);
}

.bar.classes_warrior{
  color: hsl(31, 44%, 61%);
}

.bar.classes_warrior:hover{
  color: hsl(31, 46%, 59%);
}

.bar.classes_death_knight{
  color: hsl(356, 100%, 50%);
}

.bar.classes_death_knight:hover{
  color: hsl(356, 60%, 57%);
}
.line {
  fill: none;
  stroke-width: 1.5px;
  opacity: 0.25;
}

.line.horde {
  stroke: red;
}

.line.alliance {
  stroke: blue;
}

.line.total {
  stroke: green;
}

.title-text {

}

.circle-text {

}

.circle-legend {

}

.circle-group > circle {
  opacity: 0.85;
}

.circle-group.alliance > circle,
.circle-text.alliance,
.title-text.alliance,
.circle-legend.alliance {
  fill: blue;
}

.circle-group.horde > circle,
.circle-text.horde,
.title-text.horde,
.circle-legend.horde {
  fill: red;
}

.circle-group.total > circle,
.circle-text.total,
.title-text.total,
.circle-legend.total {
  fill: green;
}
.line {
  fill: none;
  stroke-width: 1.5px;
  opacity: 0.25;
}

.line.horde {
  stroke: red;
}

.line.alliance {
  stroke: blue;
}

.line.total {
  stroke: green;
}

.title-text {

}

.circle-text {

}

.circle-legend {

}

.circle-group > circle {
  opacity: 0.85;
}

.circle-group.alliance > circle,
.circle-text.alliance,
.title-text.alliance,
.circle-legend.alliance {
  fill: blue;
}

.circle-group.horde > circle,
.circle-text.horde,
.title-text.horde,
.circle-legend.horde {
  fill: red;
}

.circle-group.total > circle,
.circle-text.total,
.title-text.total,
.circle-legend.total {
  fill: green;
}

.fix-slider-rail {
  left:0;
}
.axis path {
  display: none;
}
  
g.tick line {
  stroke: none;
}

.MuiTableCell-footer {
  border-bottom: none !important;
}

.MuiTableCell-head {
  border-bottom: none !important;
}
header {
  background-color: #36A4AA;
  display: flex;
  position: fixed;
  z-index: 500;
  width: 100%;
  top: 0;
  left: 0;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}

.title {
  padding-left: 20px;
  text-transform: uppercase;
}

.title-link {
  text-shadow: 2px 2px 2px rgba(48, 48, 48, 1);
  font-family: 'Press Start 2P', cursive;
  font-weight: 900;
  font-size: 24px;
  text-decoration: none;
  color: #fff;
}

.title-link > span {
  color: #E73D1A;
}

nav {
  padding-right: 20px;
}

nav a {
  padding: 0px 10px;
  text-decoration: none;
  color: #fff;
  padding-bottom: 17px;
  padding-top: 18px;
}

nav a:hover {
  background-color: hsl(183, 52%, 34%);
  border-bottom: 2px solid hsl(183, 52%, 34%);
}


nav a.active {
  border-bottom: 2px solid #fff;
}

nav a.active:hover {
  background-color: #36A4AA;
}

@media (max-width: 1020px) { 
  header {
    flex-direction: column;
    justify-content: space-evenly;
    height: 90px;
  }
  nav {
    padding: 0;
  }
  nav a {
    padding-bottom: 10px;
  }
  nav a:hover {
    padding-top: 12px;
  }
}

@media (max-width: 660px) {
  header {
    height: 50px;
    justify-content: center;
  }
  nav {
    font-size: 11px;
  }
  .title {
    padding: 0;
  }
  .title-link {
    font-size: 13px;
  }
  nav a {
    padding-bottom: 5px;
  }
  nav a:hover {
    padding-top: 2px;
    border-bottom: 1px solid hsl(183, 52%, 34%);
  }
  nav a.active {
    border-bottom: 1px solid #fff;
  }
}
