.character-chart {
  padding-top: 10px;
  text-align: center;
  max-width: 700px;
}

@media (max-width: 700px) {
  .character-chart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .character-chart > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}


.character-chart h2,
.character-chart h3,
.character-chart h4 {
  margin: 0;
}

.character-chart text {
  font-size: 12px;
}